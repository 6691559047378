<template>
  <div class="container">
    <my-header :isSenior.sync="isSenior" />
    <div class="box">
      <div class="search">
        <el-input v-model="Title" placeholder="请输入中文题名">
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="onSearch"
          ></el-button
        ></el-input>
      </div>
      <el-table
        :data="tableData"
        border
        style="width: 100%"
        :header-cell-style="{
          background: '#4f81bd',
          color: '#fff',
        }"
      >
        <el-table-column prop="surname" label="姓氏" width="100px">
        </el-table-column>
        <el-table-column prop="sourceID" label="sourceID" width="240px">
        </el-table-column>
        <el-table-column prop="title" label="题名" width="240px">
        </el-table-column>
        <el-table-column prop="area" label="地区" width="140px">
        </el-table-column>
        <el-table-column prop="version" label="版本"> </el-table-column>
        <el-table-column prop="pages" label="页数" width="140px">
        </el-table-column>
        <el-table-column prop="dTime" label="时间" width="140px">
        </el-table-column>
        <el-table-column fixed="right" label="操作" width="100">
          <template slot-scope="scope">
            <el-button @click="handleClick(scope.row)" type="text" size="small"
              >查看</el-button
            >
            <!-- v-show="scope.row.eBookForImgGenerated" -->
            <el-button @click="gotoEbook(scope.row)" type="text" size="small">
              <!-- 电子书 -->
              原图
            </el-button>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <el-pagination
      :hide-on-single-page="true"
      @size-change="handleSizeChange"
      @current-change="handleCurrentChange"
      :current-page="pageIndex"
      :page-sizes="[10, 20, 30, 40]"
      :page-size="pageSize"
      layout="total, sizes, prev, pager, next, jumper"
      :total="total"
    >
    </el-pagination>
  </div>
</template>
  
  <script>
import myHeader from "../../components/header/index.vue";
import { getGenealogy } from "../../api/literature.js";
export default {
  components: {
    myHeader,
  },
  data() {
    return {
      isSenior: false,
      tableData: [],
      pageIndex: 1,
      pageSize: 10,
      total: 0,
      Area: "",
      Title: "",
    };
  },
  created() {
    this.Area = this.$route.query.type;
    this.pageIndex = 1;
    this.getGenealogy();
  },
  methods: {
    async getGenealogy() {
      try {
        const res = await getGenealogy({
          PageIndex: this.pageIndex,
          PageSize: this.pageSize,
          Title: this.Title,
          Area: this.Area,
        });
        this.tableData = res.items;
        this.total = res.totalCount;
      } catch (error) {
        console.log(error, "error");
      }
    },
    handleSizeChange(size) {
      this.pageSize = size;
      this.getGenealogy();
    },
    handleCurrentChange(index) {
      this.pageIndex = index;
      this.getGenealogy();
    },
    onSearch() {
      this.pageIndex = 1;
      this.getGenealogy();
    },
    handleClick(data) {
      let routeData = this.$router.resolve({
        path: "/genealogyInfo",
        query: {
          sourceID: data.sourceID,
        },
      });
      window.open(routeData.href, "_blank");
    },
    gotoEbook(data) {
      let routeData = this.$router.resolve({
        path: "/ebook",
        query: {
          sourceID: data.sourceID,
          id: data.id,
        },
      });
      window.open(routeData.href, "_blank");
    },
  },
};
</script>
  
  <style scoped lang="scss">
.container {
  .box {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .search {
      display: flex;
      margin-bottom: 10px;
      align-items: center;
      span {
        display: inline-block;
        width: 100px;
      }
      .el-input {
        width: 300px;
      }
    }
  }
  .table {
    width: 1300px;
    padding: 30px 0 0 0;
    margin: 0 auto;

    .title {
      padding: 10px;
      font-size: 18px;
      font-weight: 600;
      border-bottom: 1px solid #1a416c;
      background: #eee;
    }
    .name {
      display: flex;
      flex-wrap: wrap;
      background: #fff;

      .item {
        width: 250px;
        padding: 20px 5px;
        text-decoration: underline;
        color: #4c69bb;
        cursor: pointer;
        font-size: 16px;
      }

      .item:hover {
        color: red;
      }
    }
  }
  .el-pagination {
    text-align: center;
    padding-bottom: 10px;
  }
}
</style>